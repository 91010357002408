var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" }, attrs: { id: _vm.elemId } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "xl",
            title: _vm.selectorTitleLabel,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "no-close-on-esc":
              _vm.isCellEditing || _vm.editorShow || _vm.historyShow,
            "modal-class": [_vm.elemId],
            "content-class": "shadow",
          },
          on: { hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.selectorTitleLabel) + " "),
                  ]),
                  _vm.activeTab != -1
                    ? [
                        _c(
                          "b-button",
                          {
                            staticClass: "history-button",
                            attrs: { variant: "secondary", size: "sm" },
                            on: {
                              click: function ($event) {
                                _vm.historyShow = true
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "clock-rotate-left"] },
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("button.history")) + " "
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.close")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selectorShow,
            callback: function ($$v) {
              _vm.selectorShow = $$v
            },
            expression: "selectorShow",
          },
        },
        [
          _vm.isMissingData
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(
                      _vm._s(_vm.$t("entity_selector.error.fail_to_show_data"))
                    ),
                  ]),
                ]),
              ]
            : [
                _vm.alertObj.msg != null
                  ? _c("AlertFeedback", {
                      attrs: {
                        msg: _vm.alertObj.msg,
                        details: _vm.alertObj.msgDetails.list,
                        detailTitle: _vm.alertObj.msgDetails.title,
                        alertState: _vm.alertObj.state,
                      },
                      on: { resetAlert: _vm.resetAlert },
                    })
                  : _vm._e(),
                _vm.enumList != null && Object.keys(_vm.enumList).length > 0
                  ? _c(
                      "div",
                      { staticClass: "selector-navbar" },
                      [
                        _c(
                          "PriorityNavigation",
                          {
                            ref: "selector-nav",
                            staticClass: "selector-nav",
                            attrs: { ulClassName: "nav nav-pills" },
                          },
                          _vm._l(_vm.enumList, function (value, name, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                staticClass: "nav-pills nav-link",
                                class: { active: index == 0 },
                                attrs: { name: name },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.selectorNavClick.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "a",
                                  { attrs: { href: "#", target: "_self" } },
                                  [_vm._v(_vm._s(_vm.getTabLabel(name)))]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "mt-3" },
                  [
                    _c(
                      "PriorityNavigation",
                      { staticClass: "grid-toolbar border" },
                      [
                        _vm.activeTabValue.addingEnabled
                          ? _c(
                              "li",
                              { attrs: { id: "BTN_ADD_" + _vm.elemId } },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { disabled: _vm.disableAdd },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openEditor(
                                          true,
                                          _vm.activeTabName
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      style: {
                                        color: "var(--grid-toolbar-button)",
                                      },
                                      attrs: { icon: ["far", "plus"] },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      target: "BTN_ADD_" + _vm.elemId,
                                      triggers: "hover",
                                      placement: "top",
                                      boundary: "viewport",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("button.add")) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.activeTabValue.renamingEnabled
                          ? _c(
                              "li",
                              { attrs: { id: "BTN_EDIT_" + _vm.elemId } },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { disabled: _vm.disableEdit },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openEditor(
                                          false,
                                          _vm.activeTabName
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "pen-to-square"] },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      target: "BTN_EDIT_" + _vm.elemId,
                                      triggers: "hover",
                                      placement: "top",
                                      boundary: "viewport",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("button.edit")) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.activeTabValue.removingEnabled
                          ? _c(
                              "li",
                              { attrs: { id: "BTN_DELETE_" + _vm.elemId } },
                              [
                                _c(
                                  "b-btn",
                                  {
                                    attrs: { disabled: _vm.disableDelete },
                                    on: {
                                      click: function ($event) {
                                        return _vm.rowDelete(_vm.activeTabName)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: ["far", "trash-can"] },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      target: "BTN_DELETE_" + _vm.elemId,
                                      triggers: "hover",
                                      placement: "top",
                                      boundary: "viewport",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("button.delete")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "li",
                          { attrs: { id: "BTN_MOVEDOWN_" + _vm.elemId } },
                          [
                            _c(
                              "b-btn",
                              {
                                attrs: { disabled: _vm.disableReorder },
                                on: {
                                  click: function ($event) {
                                    return _vm.moveRow(_vm.activeTabName, false)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "arrow-down"] },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: "BTN_MOVEDOWN_" + _vm.elemId,
                                  triggers: "hover",
                                  placement: "top",
                                  boundary: "viewport",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("button.move_down")) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "li",
                          { attrs: { id: "BTN_MOVEUP_" + _vm.elemId } },
                          [
                            _c(
                              "b-btn",
                              {
                                attrs: { disabled: _vm.disableReorder },
                                on: {
                                  click: function ($event) {
                                    return _vm.moveRow(_vm.activeTabName, true)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "arrow-up"] },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "b-popover",
                              {
                                attrs: {
                                  target: "BTN_MOVEUP_" + _vm.elemId,
                                  triggers: "hover",
                                  placement: "top",
                                  boundary: "viewport",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("button.move_up")) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("ag-grid-vue", {
                      staticClass:
                        "ag-theme-balham selector-grid-height-with-tabs",
                      staticStyle: { width: "100%" },
                      attrs: {
                        id: "enum-grid",
                        gridOptions: _vm.gridOptions,
                        columnDefs: _vm.columnDefs,
                        context: _vm.context,
                        defaultColDef: _vm.defaultColDef,
                        getRowId: function (params) {
                          return params.data.num
                        },
                        pagination: "",
                        paginationPageSize: 1000,
                        cacheBlockSize: 10000,
                        rowData: _vm.rowData,
                        rowMultiSelectWithClick: false,
                        rowSelection: "multiple",
                        serverSideInfiniteScroll: true,
                        sideBar: false,
                        suppressContextMenu: "",
                        suppressDragLeaveHidesColumns: "",
                        suppressCellFocus: false,
                        singleClickEdit: false,
                        enableRangeSelection: "",
                        suppressMultiSort: "",
                        suppressRowClickSelection: "",
                        navigateToNextCell: _vm.navigateToNextCell,
                        tabToNextCell: _vm.tabToNextCell,
                        noRowsOverlayComponent: "noRowsOverlay",
                        noRowsOverlayComponentParams:
                          _vm.noRowsOverlayComponentParams,
                        overlayLoadingTemplate: _vm.overlayLoadingTemplate,
                        enableCellEditingOnBackspace: "",
                        processCellForClipboard: _vm.processCellForClipboard,
                      },
                      on: {
                        "grid-ready": _vm.onGridReady,
                        "cell-key-down": _vm.onCellKeyDown,
                        "cell-focused": _vm.cellFocused,
                        "cell-editing-started": _vm.onCellEditingStarted,
                        "cell-editing-stopped": _vm.onCellEditingStopped,
                        "paste-start": _vm.onPasteStart,
                        "paste-end": _vm.onPasteEnd,
                      },
                    }),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _vm.editorShow
        ? _c("EnumModal", {
            attrs: {
              selectedCode: _vm.editorCode,
              selectedNum: _vm.editorNum,
              enumName: _vm.activeTabName,
              enumObj: _vm.activeTabValue,
              show: _vm.editorShow,
            },
            on: {
              "update:show": function ($event) {
                _vm.editorShow = $event
              },
              ok: _vm.editorOk,
            },
          })
        : _vm._e(),
      _vm.enumList != null &&
      _vm.enumList.CurrencyEnum != null &&
      Array.isArray(_vm.enumList.CurrencyEnum.list)
        ? _c("EnumCurrencySelectorModal", {
            attrs: {
              show: _vm.currencySelectorShow,
              preselected: _vm.enumList.CurrencyEnum.list,
            },
            on: {
              "update:show": function ($event) {
                _vm.currencySelectorShow = $event
              },
              ok: _vm.currencySelectorOk,
            },
          })
        : _vm._e(),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("entity_selector.confirm_deletion_title"),
            "content-class": "entity-delete-modal shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.ecConfirmDeleteShow = false
            },
            ok: _vm.ecConfirmDeleteOk,
            cancel: _vm.ecConfirmDeleteCancel,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _vm.ecShowApplyAllCheckbox
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.entityCol.applyAll,
                            callback: function ($$v) {
                              _vm.$set(_vm.entityCol, "applyAll", $$v)
                            },
                            expression: "entityCol.applyAll",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.ecConfirmDeleteShow,
            callback: function ($$v) {
              _vm.ecConfirmDeleteShow = $$v
            },
            expression: "ecConfirmDeleteShow",
          },
        },
        [_c("p", [_vm._v(_vm._s(_vm.ecConfirmDeleteStatement))])]
      ),
      _vm.activeTab != -1
        ? [
            _vm.historyShow
              ? _c("EnumHistoryModal", {
                  attrs: { show: _vm.historyShow, enumType: _vm.activeTabName },
                  on: {
                    "update:show": function ($event) {
                      _vm.historyShow = $event
                    },
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }